import React from "react"
import PropTypes from 'prop-types'

// import route
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// import components
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// import constants
import { APP_NAME } from "helpers/constants"

// import logo
import logo from "../../assets/images/logos/logo.png"


const Auther = ({ children, location }) => {

	console.log(location.pathname)
	console.log(location.pathname.includes("/accuse-de-reception/"))

	return (
		<React.Fragment>
			<div className={`${location.pathname.includes("/login") ? "auth-pages" : ""}`}>
				{children}
			</div>
		</React.Fragment>
	)

}
Auther.propTypes = {
	children: PropTypes.node,
	t: PropTypes.any,
	location: PropTypes.object,
}
export default withRouter(withTranslation()(Auther))