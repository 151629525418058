import { takeEvery, put, call } from "redux-saga/effects"

// import services
import { login, logout, updatePassword } from "services/authService"

// import actions
import { authError, authSuccess, isUnauthorized } from "./actions"

// import redux constants
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE } from "./constants"

// import constants
import { CUSTOMER_KEY } from "helpers/constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { formatUserWithName, isSuccessfulRequest } from "helpers/utilities"

// Login errors messages
const AUTH_ERRORS = {
	"LOGIN": {
		400: "Identifiants de connexion invalides.",
		401: "Le mot passe est incorrect.",
		404: "L'identifant n'existe pas.",
		403: "L'utilisateur connecté est désactivé."
	},
	"LOGOUT": {},
	"UPDATE": {
		403: "L'ancien mot de passe ne correspond pas"
	}
}

function* onLoginUser({ payload }) {
	try {
		const response = yield call(login, payload.data)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			let logged = formatUserWithName(response.data)
			localStorage.setItem(CUSTOMER_KEY, JSON.stringify(logged))
			yield put(authSuccess())
		} else {
			console.error(response, "LOGIN ERROR")
			yield put(authError(errorMessage(response, AUTH_ERRORS.LOGIN).message))
		}
	} catch (error) {
		console.error(error, "LOGIN ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onLogoutUser({ payload }) {
	try {
		const response = yield call(logout)
		let { unauth, message } = errorMessage(response, AUTH_ERRORS.LOGOUT)
		if (isSuccessfulRequest(response.status, response.statusText) || unauth) {
			yield put(authSuccess())
		} else {
			console.error(response, "LOGOUT ERROR")
			yield put(authError(message))
		}
	} catch (error) {
		console.error(error, "LOGOUT ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onUpdatePassword({ payload }) {
	try {
		const response = yield call(updatePassword, payload.data)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			const loggedOut = yield call(logout)
			let { unauth, message } = errorMessage(loggedOut, AUTH_ERRORS.LOGOUT)
			if (isSuccessfulRequest(loggedOut.status, loggedOut.statusText) || unauth) {
				yield put(authSuccess())
			} else {
				console.error(loggedOut, "LOGOUT ERROR")
				yield put(authError(message))
			}
		} else {
			console.error(response, "UPDATE PASSWORD ERROR")
			let { unauth, message } = errorMessage(response, AUTH_ERRORS.UPDATE)
			yield put(authError((unauth ? "" : message)))
			if (unauth) yield put(isUnauthorized())
		}
	} catch (error) {
		console.error(error, "UPDATE PASSWORD ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* AuthSaga() {
	yield takeEvery(AUTH_LOGIN, onLoginUser)
	yield takeEvery(AUTH_LOGOUT, onLogoutUser)
	yield takeEvery(AUTH_UPDATE, onUpdatePassword)
}

export default AuthSaga