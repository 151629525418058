import React from "react"
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import bootstrap components
import { Alert, Container, Label, Card, CardBody, Row, Col } from "reactstrap"

// import form components
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"

// import translation
import { withTranslation } from "react-i18next"

// import redux, actions
import { connect } from "react-redux"
import { authLogin, authReset } from "store/actions"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import lottie
import Lottie from "lottie-react"
import deliveryGuy from "../../assets/lotties/delivery-guy.json"
import deliveryChecked from "../../assets/lotties/delivery-checked.json"

// import custom common components
import Loader from "components/common/Loader"

// import constants
import { APP_NAME } from 'helpers/constants'

// import logo
import logo from "../../assets/images/logos/logo.png"
import poste from '../../assets/images/logos/logo-poste.png'
import playStore from "../../assets/images/avalaible_play_store.png"

// TESTS CREDENTIALS
const USERS_CREDENTIALS = {
	username: "diezze",
	password: "azerty123"
}


class Login extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			showPassword: false,
			showForm: false
		}
	}

	componentDidMount() {
		try {//Reset state
			this.props.onReset(true)
		} catch (error) {
			console.error(error)
		}
	}

	componentDidUpdate(prevProps) {
		try {//Handle state changes
			if (!equal(prevProps, this.props)) {
				let { history, success } = this.props
				if (success) {
					toast.success(
						"Vous êtes connecté ! Vous pouvez accéder à votre espace de travail.",
						{
							onClose: () => history.push("/mes-courses")
						}
					)
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * Toggle modal.
	 * 
	 * @param {*} option 
	 */
	onToggled = (option = "showForm", visible = false) => {
		this.setState({ [option]: visible })
	}

	/**
	 * On submit event
	 * 
	 * @param {*} e 
	 * @param {*} values 
	 */
	onSubmited = (e, values) => {
		e.preventDefault();//Prevent default behaviour

		try {//Launch login
			this.props.onLogin(values)
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On password toggled.
	 * 
	 */
	onPasswordToggled = () => {
		try {
			this.setState(prevState => ({
				showPassword: !prevState.showPassword,
			}))
		} catch (error) {
			console.error(error)
		}
	}


	render() {
		const { error, loading, success } = this.props
		const { showPassword, showForm } = this.state

		return (
			<React.Fragment>
				<Helmet>
					<title>{APP_NAME} | Accueil - Connexion</title>
				</Helmet>

				<div className="login-pages flex-column-reverse flex-md-row">
					<div className="login-animation">
						<Lottie
							animationData={deliveryGuy}
							loop={true}
							style={{
								height: "100%",
								width: "100%",
								position: "absolute",
								top: "0",
								bottom: "0",
								right: "0",
								left: "0"
							}}
						/>
						<div
							style={{
								position: "relative",
								height: "100%", width: "100%",
								display: "flex", alignItems: "end"
							}}
						>
							<div className="d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
								<a
									href="https://play.google.com/store/apps/details?id=bj.laposte.rapid"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={playStore}
										alt="avalaible on play store"
										className="img-fluid m-2"
										width={120} height={100}
									/>
								</a>
								<div className="m-2">
									<span className="m-0 text-dark">
										Support : <strong>(+229) 51 21 11 11</strong>.
									</span>
									<a
										href="/confidentialite-et-utilisation"
										className="ms-2"
										target="_blank"
										rel="noopener noreferrer"
									>
										Confidentialité &amp; Utilisation
									</a>.
								</div>
							</div>
						</div>
					</div>

					<div className="login-form pt-3 pt-md-0">
						<div className="flex-grow-1 d-flex">
							<AvForm onValidSubmit={this.onSubmited} className="login-avform">
								<div className="mb-4 d-flex align-items-center justify-content-center">
									<div className="me-2">
										<img
											src={logo}
											alt="app logo"
											className="img-fluid"
											width={70} height={70}
										/>
									</div>
									<div className="ms-2">
										<h5 className="text-dark fw-bold mb-0">
											{APP_NAME}
										</h5>
										<p className="text-secondary mb-0">
											<strong>Bienvenue cher.ère client.e !</strong>
											<small className="d-block text-muted">
												Connectez-vous à votre compte.
											</small>
										</p>
									</div>
								</div>

								{(error && (error != "")) ? (
									<Alert color="danger">
										{error}
									</Alert>
								) : null}

								<div className="mb-3">
									<AvField
										type="text"
										name="username"
										label="Identifiant"
										className="form-control"
										placeholder="Entrez votre identifiant"
										validate={{
											required: {
												value: true,
												errorMessage: 'L\'identifiant est obligatoire.',
											},
										}}
									/>
								</div>

								<div className="mb-3">
									<AvGroup>
										<Label> Votre mot de passe </Label>
										<div className="input-group">
											<AvInput
												type={`${(showPassword) ? "text" : "password"}`}
												name="password"
												className="password-control form-control"
												placeholder="Entrez votre mot de passe"
												minLength="8"
												aria-label="toggle-pwd"
												aria-describedby="toggle-pwd-input-addon"
												required
											/>
											<span
												id="toggle-pwd-input-addon"
												className="toggle-password-btn input-group-text h-cursor"
												onClick={this.onPasswordToggled}
											>
												<i className={`text-muted bx bx-xs bxs-${(showPassword) ? "hide" : "show"}`} />
											</span>
											<AvFeedback>Le mot de passe a au moins 8 caractères.</AvFeedback>
										</div>
									</AvGroup>
								</div>

								<div className="mt-4 d-grid">
									{loading ? (
										<Loader color="primary" />
									) : success ? (
										<Alert color="primary">Redirection...</Alert>
									) : (
										<button className="btn btn-primary btn-block" type="submit">
											Se connecter
										</button>
									)}
								</div>
							</AvForm>
						</div>
						<div className="d-flex align-items-center justify-content-center px-2 py-3 py-md-5">
							<img
								src={poste}
								alt="poste logo"
								className="img-fluid"
								width={100} height={100}
							/>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

}
Login.propTypes = {
	t: PropTypes.any,
	history: PropTypes.any,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.string,
	onLogin: PropTypes.func,
	onReset: PropTypes.func,
}
const mapStateToProps = state => ({
	error: state.auth.error,
	success: state.auth.success,
	loading: state.auth.loading,
})
const mapDispatchToProps = dispatch => ({
	onLogin: (data) => dispatch(authLogin(data)),
	onReset: (all) => dispatch(authReset(all)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Login)))
