import { AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_RESET, AUTH_SUCCESS, AUTH_UNAUTHORIZED, AUTH_UPDATE } from "./constants"

export const authLogin = (data) => ({
	type: AUTH_LOGIN,
	payload: { data },
})

export const authLogout = () => ({
	type: AUTH_LOGOUT,
})

export const authUpdate = (data) => ({
	type: AUTH_UPDATE,
	payload: { data },
})

export const authSuccess = () => ({
	type: AUTH_SUCCESS
})

export const authError = (error) => ({
	type: AUTH_ERROR,
	payload: { error },
})

export const authReset = (all) => ({
	type: AUTH_RESET,
	payload: { all },
})

export const isUnauthorized = () => ({
	type: AUTH_UNAUTHORIZED,
})