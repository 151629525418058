import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import deep-equal
import equal from "deep-equal"

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import toaster
import { toast } from 'react-toastify'

// import store and actions
import { connect } from 'react-redux'
import { editInvoice, resetInvoices, setInvoices } from 'store/actions'

// import utilities
import { formatPhoneNumberForKkiapay, formatPhoneNumberForRequest } from 'helpers/utilities'

// import constants
import { INVOICES_EDIT, CUSTOMER_KEY } from 'helpers/constants'

// import custom common components
import PayButton from 'components/common/PayButton'
import Loader from 'components/common/Loader'


class InvoicePay extends React.Component {

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onClosed } = this.props

                if (success) {
                    if (option === INVOICES_EDIT) {
                        // Display toasts
                        toast.success(`Facture payée avec succès.`)

                        //Close modal
                        onClosed("payVisible", false)
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            let { onSetInvoices, onResetInvoices } = this.props
            onResetInvoices(false)
            onSetInvoices({ invoice: {} })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * on payed.
     * Payment process callback.
     * 
     * @param {*} response 
     */
    onPayed = (response) => {
        try {
            let { invoice, option, onEditInvoice } = this.props
            if (option === INVOICES_EDIT) {
                onEditInvoice({
                    bill_id: invoice._id,
                    transaction_id: response.transactionId
                }, option)
            }
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const { opened, option, classes, error, loading, success, onClosed, invoice } = this.props
        const { bill_reference, bill_price, created_at } = invoice
        const { name, phone, email } = JSON.parse(localStorage.getItem(CUSTOMER_KEY))

        return (
            <React.Fragment>
                <Modal isOpen={opened} centered={true} className={classes}>
                    <ModalHeader tag="h4">
                        Paiement d'une facture
                        {(bill_reference && created_at) ? (
                            <span className="d-block fs-12 text-secondary">
                                Facture {" "}
                                <strong> #{bill_reference} </strong>
                                du {" "}
                                <strong> {`${moment.unix(created_at).format('DD MMM YYYY')}`} </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl="12">
                                <p className="m-3 fs-18 text-center">
                                    Lancer le paiement de {" "}
                                    <strong className="fs-20 mb-1">
                                        {bill_price ? `${bill_price}(XOF)` : ""}
                                    </strong> {" "} ?
                                    <small className="d-block fs-12 text-danger lh-1">
                                        *En continuant vous allez lancer le processus de paiement, veuillez ne pas
                                        fermer l'onglet ou éteindre votre appareil avant la fin du processus.
                                    </small>
                                </p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {(loading) ? (
                            <Loader color="warning" />
                        ) : (
                            <>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-light"
                                    onClick={() => onClosed("payVisible", false)}
                                >
                                    Fermer
                                </button>
                                <PayButton
                                    label="Payer"
                                    amount={((process.env.REACT_APP_ENV_PROD === "true") ? bill_price : 1)}
                                    classes="btn btn-accent"
                                    onPayed={this.onPayed}
                                />
                            </>
                        )}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

}
InvoicePay.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    onClosed: PropTypes.func,
    invoice: PropTypes.object,
}
InvoicePay.defaultProps = {
    opened: false,
    classes: "invoice-pay-modal",
    invoice: {}
}
const mapStateToProps = state => ({
    invoice: state.factures.invoice,
    error: state.factures.error,
    option: state.factures.option,
    success: state.factures.success,
    loading: state.factures.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onEditInvoice: (data, option) => dispatch(editInvoice(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(InvoicePay)))