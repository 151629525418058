import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import { resetOrders, searchOrders, setOrders } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import { APP_NAME, ORDERS_RESEARCH } from 'helpers/constants'

// import utilities
import { } from 'helpers/utilities'

// import orders custom components
import OrderList from 'components/orders/OrderList'
import OrderSearch from 'components/orders/OrderSearch'


class SearchOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            query: {
                start: moment(),
                end: moment(),
            },
            cardVisible: false,
        }
    }

    componentDidMount() {
        try {// Load searches
            // console.log("SEARCH ORDERS MOUNTED")
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {// Check state updates
            // console.log("SEARCH ORDERS UPDATED")
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "cardVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            // Get target
            let { checked, value, name, type } = event.target

            // Save values
            this.setState({
                query: {
                    ...this.state.query,
                    [name]: ((type === "checkbox") ? checked : value)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onRanged = (start, end) => {
        try {
            //Save values
            this.setState({
                query: {
                    ...this.state.query,
                    start: start, end: end
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On searched.
     *  
     * @param {*} list 
     * @returns 
     */
    onResearched = () => {
        try {
            console.log("ON RESEARCHED")
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On searched.
     *  
     * @param {*} list 
     * @returns 
     */
    onRetrieved = () => {
        try {
            console.log("ON RETRIEVED")
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onRefreshed.
     * 
     */
    onRefreshed = (list, selected = {}) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Save filter
            onSetOrders({ filtereds: list, filter: selected })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Showed.
     * 
     * @param {*} selected 
     * @param {*} option 
     */
    onShowed = (selected, option) => {
        try {
            console.log({ selected, option }, "ON SHOWED")
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { t, alls, orders, order, filter, loading, success, error, option } = this.props
        const { cardVisible, query } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Rechercher une course")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Rechercher une course")} />

                        <Row className="mb-2">
                            <Col xl="3" sm="12" className="pe-3 pe-xxl-4">
                                <OrderSearch
                                    onChanged={this.onChanged}
                                    onRanged={this.onRanged}
                                    onRetrieved={this.onRetrieved}
                                    onResearched={this.onResearched}
                                    query={query}
                                />
                            </Col>

                            <Col xl="9" sm="12" className="ps-3 ps-xxl-4">
                                <Row>
                                    {((error != "") && [ORDERS_RESEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Alert color="danger" className="m-0">
                                                {error}
                                            </Alert>
                                        </Col>
                                    ) : (loading && [ORDERS_RESEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Loader color="primary" />
                                        </Col>
                                    ) : (
                                        <OrderList
                                            alls={alls}
                                            orders={orders}
                                            filter={filter}
                                            onShowed={this.onShowed}
                                            onRefreshed={this.onRefreshed}
                                        />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}
SearchOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    orders: PropTypes.array,
    order: PropTypes.object,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
    onShowOrder: PropTypes.func
}
const mapStateToProps = state => ({
    alls: state.commandes.orders,
    orders: state.commandes.filtereds,
    order: state.commandes.order,
    filter: state.commandes.filter,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onSearchOrders: (data, option) => dispatch(searchOrders(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SearchOrders)))