// import constants
import {
    CHARACTERS, CONSTANTS_LIST, MESSAGE_OK, STATUS_OK
} from "./constants"

// import error
import { ERROR_401 } from "./erreurs"


/**
 * open pdf viewer.
 * 
 * @param {*} pdfPath 
 */
export const openPdfViewer = (pdfPath) => {
    try {
        window.open(pdfPath)
    } catch (error) {
        console.error(error)
    }
}

/**
 * copy text to clipboard.
 * 
 * @param {*} text 
 * @returns 
 */
export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

/**
 * Format time to string.
 * 
 * @param {*} time 
 * @returns 
 */
export const formatTimeToString = (time) => {
    try {
        return ((time < 10) ? `0${time}` : `${time}`)
    } catch (error) {
        console.error(error)
        return (`${time}`)
    }
}

/**
 * Capitalize first letter of param.
 * 
 * @param {*} string 
 * @returns string
 */
export const upperFirst = (value) => {
    return (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase())
}

/**
 * Parse integer
 * 
 * @param {*} value 
 * @returns 
 */
export const parseNumber = (value) => {
    const parsed = parseInt(value, 10)
    return (isNaN(parsed) ? 0 : parsed)
}

/**
 * Get items on [arrayFrom] but not on [arrayTo] based on [keys].
 * 
 * @param {*} arrayFrom 
 * @param {*} arrayTo 
 * @param {*} fromKey 
 * @param {*} toKey 
 * @returns array
 */
export const filterArraybyArray = (arrayFrom, arrayTo, fromKey = "_id", toKey = "_id") => {
    return arrayFrom.filter((afx) => !arrayTo.some((atx) => (afx[fromKey] === atx[toKey])))
}

/**
 * Check if request is successful.
 * 
 * @param {*} status 
 * @param {*} statusText 
 * @returns bool
 */
export const isSuccessfulRequest = (code, label = "") => {
    let successful = (code === STATUS_OK)
    if (label !== "") return (successful && (label.toLowerCase() === MESSAGE_OK))
    return successful
}

/**
 * Check if request is unauthorized.
 * 
 * @param {*} status 
 * @param {*} statusText 
 * @returns bool
 */
export const isUnauthorizedRequest = (code, label = "") => {
    let successful = (code === ERROR_401.code)
    if (label !== "") return (successful && (label.toLowerCase() === ERROR_401.label.toLowerCase()))
    return successful
}

/**
 * Get user role [attribut] with [key] or
 * Get factor vehicle [attribut] with [key] or
 * Get factor status [attribut] with [key] or
 * Get order status [attribut] with [key] or
 * 
 * @param {*} key 
 * @param {*} attribut 
 * @returns any
 */
export const getConstantByKey = (key, attribut = "") => {
    try {
        let const_object = CONSTANTS_LIST.find((item) => (item.key === key))
        return ((attribut !== "") ? const_object[attribut] : const_object)
    } catch (error) {
        console.error(error)
        return {}
    }
}

/**
 * 
 * @param {*} size 
 * @param {*} withUpper 
 * @returns string
 */
export const generateRandomCode = (size = 6, withUpper = false) => {
    if (withUpper) {
        let result = "";
        let charactersLength = CHARACTERS.length;
        for (let i = 0; i < size; i++) {
            result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    } else {
        return Math.random().toString(20).substr(2, size)
    }
}

/**
 * Format phone number
 * 
 * @param {*} value 
 * @returns string
 */
export const formatPhoneNumberForRequest = (value) => {
    try {
        if ((value.length === 11) || (value.length === 0)) return value
        if (value.length === 8) return (`229${value}`)
        let phoneData = value.split(" ");
        return (`229${phoneData[1]}${phoneData[2]}${phoneData[3]}${phoneData[4]}`)
    } catch (error) {
        console.error(error)
        return ""
    }
}

/**
 * Format phone for display.
 * 
 * @param {*} value 
 * @returns string
 */
export const formatPhoneNumberForDisplay = (value) => {
    try {
        if ((value.length === 18) || (value.length === 0)) return value
        let phone1 = value.substr(((value.length == 8) ? 0 : 3), 2)
        let phone2 = value.substr(((value.length == 8) ? 2 : 5), 2)
        let phone3 = value.substr(((value.length == 8) ? 4 : 7), 2)
        let phone4 = value.substr(((value.length == 8) ? 6 : 9), 2)
        return (`(+229) ${phone1} ${phone2} ${phone3} ${phone4}`)
    } catch (error) {
        console.error(error)
        return ""
    }
}

/**
 * Filter [list] by [attribut] based on [attributKey]
 * if [count] return list's size otherwise return list
 * 
 * @param {*} list
 * @param {*} attribut 
 * @param {*} attributKey 
 * @returns array
 */
export const filterListByAttribut = (list, attribut, count = false, attributKey = "status") => {
    try {
        let results = list.filter((item) => (
            item[attributKey] && (item[attributKey] === attribut)
        ))
        return (count ? results.length : results)
    } catch (error) {
        console.error(error)
        return (count ? 0 : [])
    }
}

/**
 * Format ArrayToString.
 * 
 * @param {*} array 
 * @returns 
 */
export const formatArrayToString = (array) => {
    try {
        return array.reduce(
            (previousValue, currentValue) => (
                `${(previousValue === "") ? "" : `${previousValue}, `}${currentValue}`
            ), ""
        )
    } catch (error) {
        console.error(error)
        return ""
    }
}

/**
 * Format User With Name.
 * 
 * @param {*} user 
 * @returns 
 */
export const formatUserWithName = (user) => {
    try {
        let { name, firstname, lastname } = user
        return {
            ...user, name: (name ? name : (firstname && lastname) ? `${firstname} ${lastname}` : "")
        }
    } catch (error) {
        console.error(error)
        return user
    }
}

/**
 * Make Human Readable.
 * 
 * @param {*} num 
 * @param {*} singular 
 * @returns 
 */
export const makeHumanReadable = (num, singular) => {
    return ((num > 0) ? (`${num}${(num > 1) ? ` ${singular}s, ` : ` ${singular}, `}`) : "");
}

/**
 * Convert to days minutes seconds from [value].
 * 
 * @param {*} value 
 * @param {*} formated 
 * @returns 
 */
export const toDaysMinutesSeconds = (value, formated = false) => {
    try {
        value = Number(value);
        let seconds = Math.floor(value % 60);
        let minutes = Math.floor((value % 3600) / 60);
        let hours = Math.floor((value % (3600 * 24)) / 3600);
        let days = Math.floor(value / (3600 * 24));

        let daysStr = ""; let hoursStr = ""; let minutesStr = ""; let secondsStr = "";

        if (formated) {
            secondsStr = makeHumanReadable(seconds, 'seconde');
            minutesStr = makeHumanReadable(minutes, 'minute');
            hoursStr = makeHumanReadable(hours, 'heure');
            daysStr = makeHumanReadable(days, 'jour');
        } else {
            daysStr = ((days > 0) ? `${days}j` : "");
            hoursStr = ((hours > 0) ? ` ${hours}h` : "");
            minutesStr = ((minutes > 0) ? ` ${minutes}m` : "");
            secondsStr = ((seconds > 0) ? ` ${seconds}s` : "");
        }

        return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
    } catch (error) {
        console.error(error)
        return ""
    }
}