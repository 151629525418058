// App Name
export const APP_NAME = "RapidPoste"

// Local Storage
export const CUSTOMER_KEY = "CUSTOMER KEY"
export const I18N_KEY = "I18N KEY"

// Request
export const STATUS_OK = 200
export const MESSAGE_OK = "ok"


// Date Formats
export const DATE_FORMATER = "YYYY-MM-DD"


// Characters
export const CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

// User Generate Password Message
export const PASSWORD_MESSAGE = "Cliquez pour générer un nouveau mot de passe. Assurez vous de copier et sauvegarder le mot de passe en lieu sûr avant de continuer."

// User Types
export const USER_AGENCY = { key: "AGENCY", name: "Agence", color: "blue", tag: "agencies" }


// Orders Options
export const ORDERS_ADD = "ORDERS_ADD"
export const ORDERS_LIST = "ORDERS_LIST"
export const ORDERS_SHOW = "ORDERS_SHOW"
export const ORDERS_RESEARCH = "ORDERS_RESEARCH"
export const ORDERS_LOCATION = "ORDERS_LOCATION"
export const ORDERS_EVALUATE = "ORDERS_EVALUATE"

// Orders Status
export const ORDERS_PENDING = { key: "PENDING", name: "En attente", label: "En attentes", color: "pending", icon: "" }
export const ORDERS_ASSIGNED = { key: "ASSIGNED", name: "Prise en charge", label: "Prises en charge", color: "assigned", icon: "" }
export const ORDERS_REPORTED = { key: "REPORTED", name: "En cours", label: "En cours avec incident", color: "danger", icon: "bxs-error-alt text-danger" }
export const ORDERS_STARTED = { key: "STARTED", name: "En cours", label: "En cours", color: "ongoing", icon: "" }
export const ORDERS_COMPLETED = { key: "COMPLETED", name: "Terminée", label: "Terminées", color: "completed", icon: "" }
export const ORDERS_UNCOMPLETED = { key: "UNCOMPLETED", name: "", label: "", color: "", icon: "" }

// Orders Natures
export const NATURES_DOCUMENT = { key: "DOCUMENT", name: "Document", icon: "bxs-file-doc", color: "document", hex: "#556ee6" }
export const NATURES_ENCOMBRANT = { key: "ENCOMBRANT", name: "Encombrant", icon: "bxs-component", color: "bulky", hex: "#34c38f" }
export const NATURES_FRAIS = { key: "FRAIS", name: "Frais", icon: "bx-brightness", color: "fresh", hex: "#50a5f1" }
export const NATURES_FRAGILE = { key: "FRAGILE", name: "Fragile", icon: "bxs-wine", color: "fragile", hex: "#ffb74d" }

// Orders Per Page
export const ORDERS_PER_PAGE = 15

// Orders Events Types
export const EVENTS_TREATMENT = { key: "TREATMENT", name: "", label: "", color: "tertiary", icon: "right-arrow" }
export const EVENTS_INCIDENT = { key: "INCIDENT", name: "Incident en cours d'acheminement", label: "Incidents signalés", color: "danger", icon: "error" }
export const EVENTS_RTS = { key: "RTS", name: "Course en RTS", label: "Courses en RTS", color: "secondary", icon: "left-arrow" }
export const EVENTS_FORWARD = { key: "FORWARD", name: "Course transferée", label: "Courses transferées", color: "warning", icon: "fast-forward" }

// Orders Location Picker Options
export const POINT_PICKUP = { key: "pickup_point", label: "Point d'enlèvement" }
export const POINT_DELIVERY = { key: "delivery_point", label: "Point de livraison" }

// Order Description Type
export const DESCRIPTION_AUDIO = "vocal"
export const DESCRIPTION_TEXT = "textual"

// Order Description Inputs
export const MIN_DESCRIPTION_INPUT = 50
export const MAX_DESCRIPTION_INPUT = 500


// Invoices Options
export const INVOICES_LIST = "INVOICES_LIST"
export const INVOICES_EDIT = "INVOICES_EDIT"
export const INVOICES_SHOW = "INVOICES_SHOW"
export const INVOICES_READ = "INVOICES_READ"
export const INVOICES_RESEARCH = "INVOICES_RESEARCH"

// Invoices Per Page
export const INVOICES_PER_PAGE = 15

// Invoices Status
export const INVOICES_PAID = { key: "PAID", name: "Payée", label: "Payées", color: "success", icon: "bxs-badge-check text-success" }
export const INVOICES_UNPAID = { key: "UNPAID", name: "Non payée", label: "Non payées", color: "muted", icon: "bxs-badge text-muted" }


// ALLS CONSTANTS
export const CONSTANTS_LIST = [
    ORDERS_PENDING, ORDERS_ASSIGNED, ORDERS_STARTED, ORDERS_COMPLETED, 
    NATURES_DOCUMENT, NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS, 
    EVENTS_TREATMENT, EVENTS_INCIDENT, EVENTS_RTS, EVENTS_FORWARD,  
    INVOICES_PAID, INVOICES_UNPAID
]