// import axios
import axios from 'axios';

// import constants
import { CUSTOMER_KEY } from 'helpers/constants';

// save bases urls
export const ACCOUNT_BASE_URL = (`${process.env.REACT_APP_API_ACCOUNT_BASE_URL}/`);
export const ORDER_BASE_URL = (`${process.env.REACT_APP_API_ORDER_BASE_URL}/`);

// init axios client
const client = axios.create({
	headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
})

// config request
client.interceptors.request.use(function (config) {
	return (config.url.includes("customers/login") ? config : {
		...config,
		headers: {
			...config.headers,
			token: extractToken()
		}
	})
}, function (error) {
	return Promise.reject(error);
});

// config response
client.interceptors.response.use(function (response) {
	return {
		data: response.data.data,
		status: response.data.status,
		statusText: response.data.message
	}
}, function (error) {
	return Promise.reject(error);
});

// extract token
const extractToken = () => {
	try {
		return JSON.parse(localStorage.getItem(CUSTOMER_KEY)).token
	} catch (error) {
		console.log(error)
		return ""
	}
}

export default client