import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorOrders, successOrders } from "./actions"
import { isUnauthorized } from "store/actions"

// import constants
import { ORDERS_CALCULATE, ORDERS_GET, ORDERS_INSERT, ORDERS_SELECT } from "./constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { isSuccessfulRequest } from "helpers/utilities"

// import services
import { calculateOrder, getOrder, insertOrder, selectOrders } from "services/orderService"


// ERRORS CUSTOMS MESSAGES
const ORDERS_ERRORS = {
    "CALCULATE": {},
    "INSERT": {},
    "GET": {
        400: "La course recherchée n'existe pas."
    },
    "SEARCHES": {
        404: "Aucune course retrouvée",
        400: "Une erreur est survenue au cours de l'opération.",
        409: "L'opération demandée a déjà été effectuée",
        500: "Une erreur est survenue au cours de l'opération.",
        "refund": {
            404: "La course à rembourser n'a pas été retrouvée",
            400: "La course ne pas être remboursée",
            409: "La course a déjà été remboursée",
            500: "Le remboursement de la course a échoué. Une erreur est survenue au cours de l'opération."
        }
    }
}


function* onSelectOrders({ payload }) {
    try {
        const response = yield call(selectOrders)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ orders: (response.data ? response.data : []) }, payload.option))
        } else {
            console.error(response, "SELECT ORDERS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "SELECT ORDERS ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onGetOrder({ payload }) {
    try {
        let { id, extra, type, option } = payload
        const response = yield call(getOrder, id, type)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ 
                order: (response.data ? {
                    ...response.data,
                    events: response.data.events.reverse(),
                    delivery_auth: (extra && (extra !== null)) ? extra : response.data.delivery_auth
                } : {}) 
            }, option))
        } else {
            console.error(response, "GET ORDER ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.GET)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "GET ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onCalculateOrder({ payload }) {
    try {
        const response = yield call(calculateOrder, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ cost: (response.data ? response.data : {}) }, payload.option))
        } else {
            console.error(response, "CALCULATE ORDER PRICING ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.CALCULATE)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "CALCULATE ORDER PRICING ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onInsertOrder({ payload }) {
    try {
        let { data, option } = payload

        let form_data = new FormData();
        form_data.append('start_lat', data.start_lat);
        form_data.append('start_lng', data.start_lng);
        form_data.append('start_address', data.start_address);
        form_data.append('start_contact', data.start_contact);
        form_data.append('delivery_lat', data.delivery_lat);
        form_data.append('delivery_lng', data.delivery_lng);
        form_data.append('delivery_address', data.delivery_address);
        form_data.append('delivery_contact', data.delivery_contact);
        form_data.append('nature', data.nature);
        form_data.append('description_text', data.description_textual);
        form_data.append('is_audio_description', data.is_audio);
        form_data.append('customer_contact', data.customer_contact);
        form_data.append('customer_name', data.customer_name);
        form_data.append('customer_id', data.customer_id);
        form_data.append('delivery_auth', data.delivery_auth);
        form_data.append('round_trip', data.round_trip);
        form_data.append('price', data.price);
        form_data.append('transaction_id', data.transaction_id);

        if (data.is_audio) {
            form_data.append('description_file', data.description_file, data.description_filename);
        }

        const response = yield call(insertOrder, form_data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            let { customer, customer_detail, customer_id } = response.data
            let formated_response = (response.data ? {
                ...response.data,
                customer: (customer_id && (customer_id !== "")) ? customer_detail : customer
            } : {})
            yield put(successOrders({ order: formated_response }, option))
        } else {
            console.error(response, "INSERT ORDER ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.INSERT)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "INSERT ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* OrderSaga() {
    yield takeEvery(ORDERS_SELECT, onSelectOrders)
    yield takeEvery(ORDERS_GET, onGetOrder)
    yield takeEvery(ORDERS_INSERT, onInsertOrder)
    yield takeEvery(ORDERS_CALCULATE, onCalculateOrder)
}

export default OrderSaga