import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import { resetInvoices, searchInvoices, setInvoices } from 'store/actions'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import { APP_NAME, INVOICES_RESEARCH } from 'helpers/constants'

// import utilities
import { } from 'helpers/utilities'

// import invoices custom components
import InvoiceList from 'components/invoices/InvoiceList'
import InvoiceSearch from 'components/invoices/InvoiceSearch'


class SearchInvoices extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            query: {
                year: moment().subtract(1, 'month').startOf('month').year(),
                month: (moment().subtract(1, 'month').startOf('month').month() + 1)
            },
            cardVisible: false
        }
    }

    componentDidMount() {
        try {// Load searches
            // console.log("SEARCH INVOICES MOUNTED")
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {// Check state updates
            // console.log("SEARCH INVOICES UPDATED")
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetInvoices(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "cardVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            // Get target
            let { checked, value, name, type } = event.target

            // Save values
            this.setState({
                query: {
                    ...this.state.query,
                    [name]: ((type === "checkbox") ? checked : value)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
    * On submited.
    * 
    * @param {*} e 
    * @param {*} values 
    */
    onResearched = (option) => {
        try {//Start treatment
            let { onSearchInvoices } = this.props
            let { month, year, code } = this.state.query

            let reference = (option === "code") ? code : ""
            let month_period = (option === "period") ? month : ""
            let year_period = (option === "period") ? year : ""

            onSearchInvoices(reference, month_period, year_period, INVOICES_RESEARCH)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onPicked = (year, month, option = "") => {
        try {
            //Save values
            this.setState({
                query: {
                    ...this.state.query,
                    year: year, month: month
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onRefreshed.
     * 
     */
    onRefreshed = (list, selected = {}) => {
        try {
            //Get props
            let { onSetInvoices, onResetInvoices } = this.props

            //Reset state
            onResetInvoices(false)

            //Save filter
            onSetInvoices({ filtereds: list, filter: selected })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Showed.
     * 
     * @param {*} id 
     * @param {*} option 
     */
    onShowed = (id, option) => {
        try {
            // Get props values
            let { alls, onResetInvoices, onSetInvoices } = this.props

            // Reset state
            onResetInvoices(false)

            // Get selected invoice item
            let selected = alls.find((item) => (item._id === id))

            // Set selected invoice item
            onSetInvoices({ invoice: { ...selected }, option: option })

            //Show modal
            this.onToggled(((option === INVOICES_EDIT) ? "payVisible" : "cardVisible"), true)
        } catch (error) {
            console.log(error)
        }
    }



    render() {
        const { t, alls, invoices, invoice, filter, loading, success, error, option } = this.props
        const { cardVisible, query } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Rechercher une facture")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Rechercher une facture")} />

                        <Row className="mb-2">
                            <Col xl="3" sm="12" className="pe-3 pe-xxl-4">
                                <InvoiceSearch 
                                    onChanged={this.onChanged}
                                    onPicked={this.onPicked}
                                    onSearched={this.onResearched}
                                    query={query}
                                />
                            </Col>

                            <Col xl="9" sm="12" className="ps-3 ps-xxl-4">
                                <Row>
                                    {((error != "") && [INVOICES_RESEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Alert color="danger" className="m-0">
                                                {error}
                                            </Alert>
                                        </Col>
                                    ) : (loading && [INVOICES_RESEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Loader color="primary" />
                                        </Col>
                                    ) : (
                                        <InvoiceList
                                            alls={alls}
                                            items={invoices}
                                            filter={filter}
                                            onShowed={this.onShowed}
                                            onRefreshed={this.onRefreshed}
                                            totalPerPage={10}
                                            itemCol={4}
                                        />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}
SearchInvoices.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    invoices: PropTypes.array,
    invoice: PropTypes.object,
    onListInvoices: PropTypes.func,
    onSetInvoices: PropTypes.func,
    onResetInvoices: PropTypes.func,
}
const mapStateToProps = state => ({
    alls: state.factures.invoices,
    invoices: state.factures.filtereds,
    invoice: state.factures.invoice,
    filter: state.factures.filter,
    error: state.factures.error,
    option: state.factures.option,
    success: state.factures.success,
    loading: state.factures.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onSearchInvoices: (reference, month, year, option) => dispatch(searchInvoices(reference, month, year, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SearchInvoices)))