import React from "react"
import PropTypes from "prop-types"

// import components
import { Row, Col, Collapse } from "reactstrap"

// import route
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"


class Navbar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showSearchMenu: false
        }
    }

    componentDidMount() {
        try {
            let matchingMenuItem = null
            const ul = document.getElementById("navigation")
            const items = ul.getElementsByTagName("a")
            for (let i = 0; i < items.length; ++i) {
                if (this.props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i]
                    break
                }
            }
            if (matchingMenuItem) {
                this.activateParentDropdown(matchingMenuItem)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * activate parent dropdown.
     * 
     * @param {*} item 
     * @returns 
     */
    activateParentDropdown = item => {
        item.classList.add("active")
        const parent = item.parentElement
        if (parent) {
            parent.classList.add("active") // li
            const parent2 = parent.parentElement
            parent2.classList.add("active") // li
            const parent3 = parent2.parentElement
            if (parent3) {
                parent3.classList.add("active") // li
                const parent4 = parent3.parentElement
                if (parent4) {
                    parent4.classList.add("active") // li
                    const parent5 = parent4.parentElement
                    if (parent5) {
                        parent5.classList.add("active") // li
                        const parent6 = parent5.parentElement
                        if (parent6) {
                            parent6.classList.add("active") // li
                        }
                    }
                }
            }
        }
        return false
    }

    onDropDowned = (e) => {
        try {
            e.preventDefault()
            this.setState(prevState => ({
                isDashboard: !prevState.isDashboard,
            }))
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const { t, menuOpened } = this.props
        const { isDashboard, showSearchMenu } = this.state

        return (
            <React.Fragment>
                <div className="topnav">
                    <div className="container-fluid">
                        <nav
                            className="navbar navbar-light navbar-expand-lg topnav-menu"
                            id="navigation"
                        >
                            <Collapse
                                isOpen={menuOpened}
                                className="navbar-collapse"
                                id="topnav-menu-content"
                            >
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none d-inline-flex align-items-center"
                                            to="/mes-courses"
                                        >
                                            <i className="bx bxs-cube-alt me-2" />
                                            {t("Mes courses")} {menuOpened}
                                        </Link>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none d-inline-flex align-items-center"
                                            to="/ajouter-course"
                                        >
                                            <i className="bx bxs-add-to-queue me-2" />
                                            {t("Nouvelle course")}
                                        </Link>
                                    </li>

                                    <li className="nav-item dropdown arrow-none">
                                        <Link
                                            className="nav-link dropdown-toggle arrow-none d-inline-flex align-items-center"
                                            to="/mes-factures"
                                        >
                                            <i className="bx bx-spreadsheet me-2" />
                                            {t("Mes factures")}
                                        </Link>
                                    </li>

                                    {showSearchMenu ? (
                                        <li className="nav-item dropdown">
                                            <Link
                                                className="nav-link dropdown-toggle arrow-none"
                                                onClick={e => this.onDropDowned(e)}
                                                to="/recherches"
                                            >
                                                <i className="bx bx-search-alt me-2" />
                                                {t("Rechercher")}{" "}
                                                <div className="arrow-down" />
                                            </Link>
                                            <div
                                                className={`dropdown-menu${isDashboard ? " show" : ""}`}
                                            >
                                                <Link to="/recherches-courses" className="dropdown-item">
                                                    {t("Courses")}
                                                </Link>
                                                <Link to="/recherches-factures" className="dropdown-item">
                                                    {t("Factures")}
                                                </Link>
                                            </div>
                                        </li>
                                    ) : null}
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
Navbar.propTypes = {
    t: PropTypes.any,
    location: PropTypes.object,
    menuOpened: PropTypes.bool,
}
export default withRouter(withTranslation()(Navbar))