export async function startRecording(setRecorder) {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setRecorder((prevState) => {
            return {
                ...prevState,
                initRecording: true,
                mediaStream: stream,
            };
        });
    } catch (err) {
        console.log(err);
    }
}

export function saveRecording(recorder) {
    try {
        if (recorder.state !== "inactive") recorder.stop();
    } catch (error) {
        console.log(error)
    }
}
