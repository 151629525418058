import React, { Component } from "react"
import PropTypes from 'prop-types'

//i18n
import { I18nextProvider } from "react-i18next"
import i18n from "../../i18n"

// import router
import { withRouter } from "react-router-dom"

// import redux, actions
import { connect } from "react-redux"
import {
	changeLayout, changeLayoutWidth, changeSidebarTheme, changeTopbarTheme
} from "../../store/actions"

// import customs components
import Header from "./Header"
import Footer from "./Footer"
import Navbar from "./Navbar"


class Container extends Component {

	constructor(props) {
		super(props)

		this.state = {
			isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
			menuOpened: false,
		}
	}

	componentDidMount() {
		try {
			const {
				isPreloader, layoutWidth, topbarTheme, onChangeLayout,
				onChangeLayoutWidth, onChangeTopbarTheme
			} = this.props

			//Handle Preloader
			if (isPreloader === true) {
				document.getElementById("preloader").style.display = "block"
				document.getElementById("status").style.display = "block"

				setTimeout(function () {
					document.getElementById("preloader").style.display = "none"
					document.getElementById("status").style.display = "none"
				}, 2500)
			} else {
				document.getElementById("preloader").style.display = "none"
				document.getElementById("status").style.display = "none"
			}

			// Scroll Top to 0
			window.scrollTo(0, 0)

			// Change layout type
			onChangeLayout("horizontal")

			// Change topbar, layout theme
			if (topbarTheme) onChangeTopbarTheme(topbarTheme)
			if (layoutWidth) onChangeLayoutWidth(layoutWidth)
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * Menu opened events.
	 * 
	 */
	onMenuOpened = () => {
		this.setState(prevState => ({
			menuOpened: !prevState.menuOpened,
		}))
	}

	render() {

		const { isMobile, menuOpened } = this.state
		const { children } = this.props

		return (
			<React.Fragment>
				<I18nextProvider i18n={i18n}>
					<div id="preloader">
						<div id="status">
							<div className="spinner-chase">
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
							</div>
						</div>
					</div>
					<div id="layout-wrapper">
						<Header
							menuOpened={menuOpened}
							onMenuOpened={this.onMenuOpened}
						/>

						<Navbar menuOpened={menuOpened} />

						<div className="main-content">
							{children}
						</div>

						<Footer />
					</div>
				</I18nextProvider>
			</React.Fragment>
		)
	}

}
Container.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
	isPreloader: PropTypes.bool,
}
const mapStateToProps = state => ({
	...state.ground,
})
const mapDispatchToProps = dispatch => ({
	onChangeLayout: type => dispatch(changeLayout(type)),
	onChangeLayoutWidth: width => dispatch(changeLayoutWidth(width)),
	onChangeTopbarTheme: theme => dispatch(changeTopbarTheme(theme)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container))